import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

const state = {
  record: null,
  conversationStatus: null,
  conversationsStatuesState: {}, // { [id]: { hasAiToggler: boolean, isAIEnabled: boolean, hasSuggestion: boolean, hasImprovement: boolean } }
  aiNests: [],
  aiImprovement: null, // { language: 'EN', content: 'improved_text' },
  aiSuggestions: [],
  uiFlags: {
    isFetching: false,
    isUpdating: false,
    isTranslationsAdding: false,
    isTranslationsDeleting: false,
    isConversationStatusFetching: false,
    isConversationStatusUpdating: false,
    isAISuggestionLoading: false,
    isAISuggestionFailed: false,
    isAIImprovementLoading: false,
    isAIImprovementFailed: false,
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
