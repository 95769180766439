<template>
  <div class="bottom-box" :class="wrapClass">
    <div class="left-wrap">
      <ai-suggestion-button
        v-if="hasAiSuggestionButton"
        :message="message"
        :can-reply="canReply"
        @replace-text="replaceText"
      />

      <woot-button
        v-if="!hideEmojiPicker"
        v-tooltip.top-end="$t('CONVERSATION.REPLYBOX.TIP_EMOJI_ICON')"
        type="button"
        :title="$t('CONVERSATION.REPLYBOX.TIP_EMOJI_ICON')"
        :is-disabled="!canReply"
        icon="emoji"
        emoji="😊"
        color-scheme="secondary"
        variant="smooth"
        size="small"
        @click="toggleEmojiPicker"
      />

      <file-upload
        v-if="showAttachButton"
        ref="upload"
        v-tooltip.top-end="$t('CONVERSATION.REPLYBOX.TIP_ATTACH_ICON')"
        input-id="conversationAttachment"
        :size="4096 * 4096"
        :accept="allowedFileTypes"
        :multiple="enableMultipleFileUpload"
        :drop="enableDragAndDrop"
        :drop-directory="false"
        :disabled="!canReply"
        :data="{
          direct_upload_url: '/rails/active_storage/direct_uploads',
          direct_upload: true,
        }"
        @input-file="onFileUpload"
      >
        <woot-button
          class-names="button--upload"
          :title="$t('CONVERSATION.REPLYBOX.TIP_ATTACH_ICON')"
          :is-disabled="!canReply"
          icon="attach"
          emoji="📎"
          color-scheme="secondary"
          variant="smooth"
          size="small"
        />
      </file-upload>

      <woot-button
        v-if="showAudioRecorderButton"
        v-tooltip.top-end="$t('CONVERSATION.REPLYBOX.TIP_AUDIORECORDER_ICON')"
        :icon="!isRecordingAudio ? 'microphone' : 'microphone-off'"
        :is-disabled="!canReply"
        emoji="🎤"
        :color-scheme="!isRecordingAudio ? 'secondary' : 'alert'"
        variant="smooth"
        size="small"
        @click="toggleAudioRecorder"
      />

      <woot-button
        v-if="showEditorToggle"
        v-tooltip.top-end="$t('CONVERSATION.REPLYBOX.TIP_FORMAT_ICON')"
        :is-disabled="!canReply"
        icon="quote"
        emoji="🖊️"
        color-scheme="secondary"
        variant="smooth"
        size="small"
        @click="$emit('toggle-editor')"
      />

      <woot-button
        v-if="showAudioPlayStopButton"
        :icon="audioRecorderPlayStopIcon"
        :is-disabled="!canReply"
        emoji="🎤"
        color-scheme="secondary"
        variant="smooth"
        size="small"
        @click="toggleAudioRecorderPlayPause"
      >
        <span>{{ recordingAudioDurationText }}</span>
      </woot-button>

      <span
        v-if="showMessageSignatureButton"
        class="signature-toggle-container"
      >
        <woot-button
          v-tooltip.top-end="signatureToggleTooltip"
          class="signature-toggle"
          icon="signature"
          color-scheme="secondary"
          variant="smooth"
          size="small"
          type="button"
          :title="signatureToggleTooltip"
          @click="toggleMessageSignature"
        />
        <select
          v-model="selectMessageSignature"
          class="signature-select"
          :disabled="!messageSignatures.length"
        >
          <option
            v-for="messageSignature in messageSignatures"
            :key="messageSignature.id"
            :value="messageSignature.id"
          >
            {{ messageSignature.name }}
          </option>
        </select>
      </span>

      <woot-button
        v-if="hasWhatsappTemplates && !isOnPrivateNote"
        v-tooltip.top-end="$t('WHATSAPP_TEMPLATES.MODAL.TITLE')"
        class="!aspect-auto !h-8 !w-auto !px-2"
        icon="whatsapp"
        color-scheme="secondary"
        variant="smooth"
        size="small"
        :title="'Whatsapp Templates'"
        @click="$emit('selectWhatsappTemplate')"
      >
        <span v-if="!canReply" class="text-xs">
          {{ $t('WHATSAPP_TEMPLATES.MODAL.BTN') }}
        </span>
      </woot-button>

      <video-call-button
        v-if="(isAWebWidgetInbox || isAPIInbox) && !isOnPrivateNote"
        :conversation-id="conversationId"
      />

      <AIAssistanceButton
        v-if="!hideAiAssistButton"
        :conversation-id="conversationId"
        :is-private-note="isOnPrivateNote"
        :can-reply="canReply"
        :message="message"
        @replace-text="replaceText"
      />

      <woot-button
        v-if="showMergeTagButton"
        v-tooltip.top-end="
          $t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.MERGE_TAG_TOOLTIP')
        "
        color-scheme="secondary"
        variant="smooth"
        size="small"
        type="button"
        @click="$emit('add-merge-tag')"
      >
        { }
      </woot-button>

      <translator-toggler
        v-if="showTranslateButton || showImmediateTranslation"
        v-model="showTranslateBoxComputed"
        :is-disabled="
          isShowTranslateBoxButtonDisabled ||
          isShowTranslateBoxButtonDisabled ||
          !canReply
        "
        :max-height="2"
        :preferred-language="preferredLanguage"
        :message="message"
        :max-length="maxLength || undefined"
        @change-message="(newValue) => $emit('change-message', newValue)"
        @change-is-translating="changeIsTranslating"
      />

      <transition v-if="!disableDragDrop" name="modal-fade">
        <div
          v-show="$refs.upload && $refs.upload.dropActive"
          class="fixed bottom-0 left-0 right-0 top-0 z-20 flex h-full w-full flex-col items-center justify-center gap-2 bg-white_transparent text-gray-600 dark:bg-black_transparent dark:text-gray-200"
        >
          <fluent-icon icon="cloud-backup" size="40" />
          <h4 class="break-words text-2xl text-gray-900 dark:text-gray-50">
            {{ $t('CONVERSATION.REPLYBOX.DRAG_DROP') }}
          </h4>
        </div>
      </transition>

      <woot-button
        v-if="enableInsertArticleInReply"
        v-tooltip.top-end="$t('HELP_CENTER.ARTICLE_SEARCH.OPEN_ARTICLE_SEARCH')"
        icon="document-text-link"
        color-scheme="secondary"
        variant="smooth"
        size="small"
        :title="$t('HELP_CENTER.ARTICLE_SEARCH.OPEN_ARTICLE_SEARCH')"
        @click="toggleInsertArticle"
      />
    </div>

    <div class="right-wrap">
      <woot-button
        v-if="!hideSendButton"
        size="small"
        :class-names="buttonClass"
        :is-disabled="isSendDisabled"
        @click="onSend"
      >
        {{ sendButtonText }}
      </woot-button>

      <div
        v-if="showTextCounter"
        class="flex items-center justify-center text-xxs leading-none text-gray-500"
      >
        {{ currentLength }}/{{ maxLength }}
      </div>
    </div>
  </div>
</template>

<script>
import FileUpload from 'vue-upload-component';
import * as ActiveStorage from 'activestorage';
import keyboardEventListenerMixins from 'shared/mixins/keyboardEventListenerMixins';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import inboxMixin from 'shared/mixins/inboxMixin';
import { FEATURE_FLAGS } from 'dashboard/featureFlags';
import {
  ALLOWED_FILE_TYPES,
  ALLOWED_FILE_TYPES_FOR_TWILIO_WHATSAPP,
  ALLOWED_FILE_TYPES_FOR_LINE,
  ALLOWED_FILE_TYPES_FOR_AIRBNB,
} from 'shared/constants/messages';
import VideoCallButton from '../VideoCallButton.vue';
import AIAssistanceButton from '../AIAssistanceButton.vue';
import { REPLY_EDITOR_MODES } from './constants';
import { mapGetters } from 'vuex';
import TranslatorToggler from '../conversation/translator/TranslatorToggler.vue';
import AiSuggestionButton from '../conversation/conversation/AiSuggestionButton.vue';

export default {
  name: 'ReplyBottomPanel',
  components: {
    FileUpload,
    VideoCallButton,
    AIAssistanceButton,
    TranslatorToggler,
    AiSuggestionButton,
  },
  mixins: [keyboardEventListenerMixins, uiSettingsMixin, inboxMixin],
  props: {
    canReply: {
      type: Boolean,
      default: true,
    },
    mode: {
      type: String,
      default: REPLY_EDITOR_MODES.REPLY,
    },
    onSend: {
      type: Function,
      default: () => {},
    },
    sendButtonText: {
      type: String,
      default: '',
    },
    recordingAudioDurationText: {
      type: String,
      default: '',
    },
    inbox: {
      type: Object,
      default: () => ({}),
    },
    showFileUpload: {
      type: Boolean,
      default: false,
    },
    showAudioRecorder: {
      type: Boolean,
      default: false,
    },
    onFileUpload: {
      type: Function,
      default: () => {},
    },
    showEmojiPicker: {
      type: Boolean,
      default: false,
    },
    toggleAudioRecorder: {
      type: Function,
      default: () => {},
    },
    toggleAudioRecorderPlayPause: {
      type: Function,
      default: () => {},
    },
    isRecordingAudio: {
      type: Boolean,
      default: false,
    },
    recordingAudioState: {
      type: String,
      default: '',
    },
    isSendDisabled: {
      type: Boolean,
      default: false,
    },
    showEditorToggle: {
      type: Boolean,
      default: false,
    },
    isOnPrivateNote: {
      type: Boolean,
      default: false,
    },
    enableMultipleFileUpload: {
      type: Boolean,
      default: true,
    },
    hasWhatsappTemplates: {
      type: Boolean,
      default: false,
    },
    conversationId: {
      type: Number,
      required: true,
    },
    message: {
      type: String,
      default: '',
    },
    hideSendButton: {
      type: Boolean,
      default: false,
    },
    hideAiAssistButton: {
      type: Boolean,
      default: true,
    },
    hideAiSuggestionButton: {
      type: Boolean,
      default: false,
    },
    showTranslateButton: {
      type: Boolean,
      default: false,
    },
    showTranslateBox: {
      type: Boolean,
      default: false,
    },
    isShowTranslateBoxButtonDisabled: {
      type: Boolean,
      default: false,
    },
    showImmediateTranslation: {
      type: Boolean,
      default: false,
    },
    isImmediateTranslationDisabled: {
      type: Boolean,
      default: false,
    },
    disableDragDrop: {
      type: Boolean,
      default: false,
    },
    showTextCounter: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      default: 0,
    },
    currentLength: {
      type: Number,
      default: 0,
    },
    showMergeTagButton: {
      type: Boolean,
      default: false,
    },
    selectedMessageSignatureId: {
      type: Number,
      default: -1,
    },
    messageSignatures: {
      type: Array,
      default: () => [],
    },
    newConversationModalActive: {
      type: Boolean,
      default: false,
    },
    portalSlug: {
      type: String,
      required: true,
    },
    hideSignatureButton: {
      type: Boolean,
      default: false,
    },
    preferredLanguage: {
      type: String,
      default: 'en-US',
    },
    hideEmojiPicker: {
      type: Boolean,
      default: false,
    },
    allowedFileTypes: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
      aiNests: 'chatbot/getAINests',
    }),

    hasAiSuggestionButton() {
      if (this.hideAiSuggestionButton) {
        return false;
      }

      return Boolean(this.aiNests?.length);
    },

    isNote() {
      return this.mode === REPLY_EDITOR_MODES.NOTE;
    },
    wrapClass() {
      return {
        'is-note-mode': this.isNote,
      };
    },
    buttonClass() {
      return {
        warning: this.isNote,
      };
    },
    showAttachButton() {
      return this.showFileUpload || this.isNote;
    },
    showAudioRecorderButton() {
      if (this.isALineChannel || this.isAIRBNBChannel) {
        return false;
      }
      // Disable audio recorder for safari browser as recording is not supported
      const isSafari = /^((?!chrome|android|crios|fxios).)*safari/i.test(
        navigator.userAgent
      );

      return (
        this.isFeatureEnabledonAccount(
          this.accountId,
          FEATURE_FLAGS.VOICE_RECORDER
        ) &&
        this.showAudioRecorder &&
        !isSafari &&
        !this.isGoogleBusinessMessengerChannel &&
        !this.isAWebWidgetInbox
      );
    },
    showAudioPlayStopButton() {
      return this.showAudioRecorder && this.isRecordingAudio;
    },

    enableDragAndDrop() {
      return !this.newConversationModalActive;
    },
    audioRecorderPlayStopIcon() {
      switch (this.recordingAudioState) {
        // playing paused recording stopped inactive destroyed
        case 'playing':
          return 'microphone-pause';
        case 'paused':
          return 'microphone-play';
        case 'stopped':
          return 'microphone-play';
        default:
          return 'microphone-stop';
      }
    },
    showMessageSignatureButton() {
      return (
        !this.isOnPrivateNote &&
        !this.hideSignatureButton &&
        this.isEmailChannelType
      );
    },
    isEmailChannelType() {
      const regex = /email/i;
      return regex.test(this.channelType);
    },
    sendWithSignature() {
      // channelType is sourced from inboxMixin
      return this.fetchSignatureFlagFromUiSettings(this.channelType);
    },
    signatureToggleTooltip() {
      return this.sendWithSignature
        ? this.$t('CONVERSATION.FOOTER.DISABLE_SIGN_TOOLTIP')
        : this.$t('CONVERSATION.FOOTER.ENABLE_SIGN_TOOLTIP');
    },

    selectMessageSignature: {
      get() {
        return this.selectedMessageSignatureId;
      },

      set(newValue) {
        this.$emit('change-selected-message-signature-id', newValue);
      },
    },
    enableInsertArticleInReply() {
      const isFeatEnabled = this.isFeatureEnabledonAccount(
        this.accountId,
        FEATURE_FLAGS.INSERT_ARTICLE_IN_REPLY
      );
      return isFeatEnabled && this.portalSlug;
    },

    showTranslateBoxComputed: {
      get() {
        return this.showTranslateBox;
      },

      set() {
        this.$emit('toggle-translate-box');
      },
    },
  },
  mounted() {
    ActiveStorage.start();
  },
  methods: {
    getKeyboardEvents() {
      return {
        'Alt+KeyA': {
          action: () => {
            this.$refs.upload.$children[1].$el.click();
          },
          allowOnFocusedInput: true,
        },
      };
    },
    toggleMessageSignature() {
      this.setSignatureFlagForInbox(this.channelType, !this.sendWithSignature);
    },
    replaceText(text) {
      this.$emit('replace-text', text);
    },
    toggleInsertArticle() {
      this.$emit('toggle-insert-article');
    },
    toggleEmojiPicker() {
      this.$emit('toggle-emoji-picker');
    },
    changeIsTranslating(newValue) {
      this.$emit('change-is-translating', newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-box {
  @apply relative flex flex-shrink-0 justify-between gap-1 bg-white px-4 pb-3 pt-1 dark:bg-gray-900;

  &.is-note-mode {
    @apply bg-yellow-100 dark:bg-yellow-950;
  }
}

.left-wrap {
  @apply flex flex-wrap items-center gap-1;

  .signature-toggle-container {
    @apply flex flex-row flex-nowrap items-center rounded-lg bg-gray-100 dark:bg-gray-700;

    .signature-toggle {
      @apply rounded-e-none border-none;
    }

    .signature-select {
      @apply m-0 h-8 w-0 border-none;

      font-size: 0;
      align-self: stretch;
      padding: 0;
      padding-right: 0px;
      padding-right: 2.25rem;
      background-color: transparent;

      option {
        font-size: 1.6rem;
      }
    }
  }
}

.right-wrap {
  @apply flex;
}

::v-deep .file-uploads {
  label {
    @apply cursor-pointer;
  }
  &-disabled {
    label {
      @apply cursor-not-allowed;
    }
  }
  &:hover button {
    @apply bg-gray-100 dark:bg-gray-800;
  }
}
</style>
